import React from 'react';
import styled from 'styled-components';

import {
  ContentfulComponentBackground,
  ContentfulPageSolutionsOverview,
  ContentfulEntry,
} from '@/../graphql-types';
import BackgroundWrapper from '@/components/BackgroundWrapper';
import {
  HeroBanner,
  CustomerLogos,
  TextBlock,
  PlatformFeatureLarge,
  ResourceCardSet,
  SolutionsCardCarousel,
  FooterCta,
  Undefined,
} from '@/components/contentful/components';
import * as Constants from '@/types/Constants';

type BackgroundComponent = ContentfulEntry & {
  background: ContentfulComponentBackground;
};

const Container = styled.div``;

interface PageSolutionsOverviewProps {
  data?: ContentfulPageSolutionsOverview;
}
const PageSolutionsOverview: React.FC<PageSolutionsOverviewProps> = (props) => {
  const { data } = props;

  // if the component has a background, then wrap it with a background
  const wrapBackground = (component, element) => {
    const hasBackground = component?.['background'] != null;
    if (hasBackground) {
      return (
        <BackgroundWrapper
          key={`background-component-${component.id}`}
          data={(component as BackgroundComponent)?.background}
        >
          {element}
        </BackgroundWrapper>
      );
    } else {
      return element;
    }
  };

  return (
    <Container className="">
      {data.heroBanner &&
        wrapBackground(
          data.heroBanner,
          <HeroBanner data={data.heroBanner as ContentfulEntry} />,
        )}

      {data.sections?.map((contentfulComponent: ContentfulEntry) => {
        // determine which contentful component should be used
        let element;
        const key = `component-${contentfulComponent.id}`;

        switch (contentfulComponent?.internal?.type) {
          case Constants.COMPONENT_SOLUTIONS_CAROUSEL:
            element = (
              <SolutionsCardCarousel key={key} data={contentfulComponent} />
            );
            break;
          case Constants.COMPONENT_CUSTOMER_LOGOS:
            element = <CustomerLogos key={key} data={contentfulComponent} />;
            break;
          case Constants.COMPONENT_PLATFORM_FEATURE_LARGE:
            element = (
              <PlatformFeatureLarge key={key} data={contentfulComponent} />
            );
            break;
          case Constants.COMPONENT_RESOURCE_CARD_SET:
            element = <ResourceCardSet key={key} data={contentfulComponent} />;
            break;
          default:
            element = (
              <Undefined
                key={key}
                message={`ComponentMapper: "${contentfulComponent?.internal?.type}" not defined.`}
              />
            );
        }

        return wrapBackground(contentfulComponent, element);
      })}

      {data?.footerCta && <FooterCta data={data.footerCta} />}
    </Container>
  );
};
export default PageSolutionsOverview;
